import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Header from 'features/searchResults/header';
import List from 'features/searchResults/list';
import { SORTING_OPTIONS } from 'src/data/searchResults';
import styles from './searchResults.module.scss';
import qs from 'qs';
import Spinner from 'components/Spinner';
import { SEARCH_IN_VEHICLES } from 'services/graphql/queries/vehicles-catalogue';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from '@apollo/client';
import Alert from 'components/Alert';
import { navigate } from 'gatsby';

const defaultSort = 'ENDING_SOONEST';
const SearchResults = ({ location }) => {
  const searchQuery = location.search
    ? qs.parse(location.search.substring(1))
    : { searchQuery: '' };

  if (searchQuery && !searchQuery.sortBy) searchQuery.sortBy = defaultSort;
  const { data, loading, error, fetchMore } = useQuery(SEARCH_IN_VEHICLES, {
    skip: !searchQuery,
    variables: {
      ...searchQuery,
      pageNumber: 0,
    },
  });

  return (
    <Layout>
      <SEO title="Search Results" />

      {error ? (
        <Alert color="danger" msg={error.message} />
      ) : loading ? (
        <Spinner />
      ) : !data ? (
        <div> No vehicles</div>
      ) : (
        <InfiniteScroll
          dataLength={data.getVehicles.vehicles.length}
          next={() => fetchData(fetchMore, searchQuery, data.getVehicles.page)}
          loader={<Spinner />}
          hasMore={data.getVehicles.nbPages > data.getVehicles.page + 1}
          hasChildren={true}
        >
          <div className="searchResults">
            <div className={styles.searchResults}>
              <div>
                <div className={styles.header}>
                  <div>
                    <h2>SEARCH RESULTS</h2>
                    <h3>
                      {data.getVehicles.nbHits} Total Results for ‘
                      {searchQuery.searchQuery} ’
                    </h3>
                  </div>
                  <Header
                    sortOptions={SORTING_OPTIONS}
                    selectedSortId={
                      searchQuery.sortBy !== defaultSort && searchQuery.sortBy
                    }
                    updateSort={value => {
                      navigate(
                        `/search-results/?${qs.stringify({
                          ...searchQuery,
                          sortBy: value,
                        })}`
                      );
                    }}
                  />
                </div>

                <div className={styles.searchList}>
                  <List vehicles={data.getVehicles.vehicles} />
                </div>
              </div>
            </div>
          </div>
        </InfiniteScroll>
      )}
    </Layout>
  );
};

export default SearchResults;
const fetchData = (fetchMore, queryParams, currentPage) => {
  fetchMore({
    variables: { ...queryParams, pageNumber: currentPage + 1 },
  });
};
