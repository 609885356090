import React, { useState } from 'react';
import Popover from 'components/Popover';
import Sort from './sort';

const Header = ({ sortOptions = [], updateSort, selectedSortId }) => {
  const [sortPopoverOpen, setSortPopoverOpen] = useState(false);
  const sortToggle = () => setSortPopoverOpen(!sortPopoverOpen);

  return (
    <Popover
      showCaretDown={true}
      active={!!selectedSortId}
      label="Sort"
      id="sort"
      popoverOpen={sortPopoverOpen}
      toggle={sortToggle}
    >
      <Sort
        options={sortOptions}
        selected={sortOptions.find(option => option.id === selectedSortId)}
        update={value => {
          if (value.id === selectedSortId) {
            updateSort({});
          } else {
            updateSort(value.id);
          }
          sortToggle();
        }}
      />
    </Popover>
  );
};

export default Header;
