const SORTING_OPTIONS = [
  {
    label: 'Year (Ascending)',
    id: 'YEAR_ASC',
  },
  {
    label: 'Year (Descending)',
    id: 'YEAR_DESC',
  },
  {
    label: 'Make (A to Z)',
    id: 'MAKE_AZ',
  },
  {
    label: 'Make (Z to A)',
    id: 'MAKE_ZA',
  },
  {
    label: 'Estimate (Low to High)',
    id: 'ESTIMATE_LH',
  },
  {
    label: 'Estimate (High to Low)',
    id: 'ESTIMATE_HL',
  },
];

export { SORTING_OPTIONS };
