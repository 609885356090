import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LabelCard from 'components/LabelCard';

const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  id: PropTypes.string,
});

const Sort = ({ selected = {}, update, options = [] }) => (
  <div>
    {options.map((option, index) => (
      <LabelCard
        key={`${option.label}${index}`}
        selected={selected.id === option.id}
        label={option.label}
        onClick={() => _.isFunction(update) && update(option)}
      />
    ))}
  </div>
);

Sort.propTypes = {
  update: PropTypes.func,
  selected: optionPropType,
  options: PropTypes.arrayOf(optionPropType),
};

export default Sort;
