import React from 'react';
import VehicleCard from 'components/VehicleCard';
import {
  getAuction,
  getPrice,
  getTag,
  getAlgoliaItemTitle,
} from 'shared/helpers/vehicles';
import { combineCloudinaryWithImages } from 'utils/image';

const List = ({ vehicles }) => {
  return (
    <div>
      {vehicles &&
        vehicles.map((vehicle, index) => (
          <VehicleCard
            key={`vehicleCard_${index}`}
            fluidImages={combineCloudinaryWithImages(vehicle, 500, 3)}
            tag={getTag(vehicle)}
            makeModel={getAlgoliaItemTitle(vehicle)}
            auction={getAuction(vehicle)}
            price={getPrice(vehicle, vehicle?.currency)}
            lotNumber={vehicle.lotNumber}
            slug={vehicle.slug}
            showDotsOnHover={true}
            salesForceId={vehicle?.salesForceId}
            onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
          />
        ))}
    </div>
  );
};

export default List;
